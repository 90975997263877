<template>
  <div class="row">
    <div class="col-md-12">
      <h4 class="title">Products List</h4>
    </div>
    <div class="col-md-12 card">
      <div class="card-body row">
        <div class="col-sm-6">
          <el-select class="select-default" v-model="pagination.per_page" placeholder="Per page" @change="changePerPage()">
            <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <fg-input
              class="input-sm"
              placeholder="Search"
              v-model="searchQuery"
              addon-right-icon="nc-icon nc-zoom-split"
            ></fg-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped" :data="tableData" border style="width: 100%;" height="600">
            <el-table-column
              v-for="(column, index) in tableColumns"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
              :sortable="column.sortable || false"
              :align="column.align"
              :header-align="column.align"
              v-if="!column.hidden"
              :fixed="column.fixed || false"
              :formatter="column.formatter || null"
            ></el-table-column>

            <el-table-column :min-width="150" fixed="right" class-name="td-actions" label="Actions">
              <template slot-scope="props">
                <p-button type="info" size="sm" icon @click="handleLike(props.$index, props.row)">
                  <i class="fa fa-eye"></i>
                </p-button>
                <p-button
                  type="success"
                  size="sm"
                  icon
                  @click="handleEdit(props.$index, props.row)"
                >
                  <i class="fa fa-edit"></i>
                </p-button>
                <p-button
                  type="danger"
                  size="sm"
                  icon
                  @click="handleDelete(props.$index, props.row)"
                >
                  <i class="fa fa-trash"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} entries</p>
        </div>
        <div class="col-sm-6">
          <p-pagination
            class="pull-right"
            v-model="pagination.current_page"
            :per-page="Number.parseInt(pagination.per_page)"
            :total="pagination.total"
            @input="changePage()"
          ></p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Vue from "vue";
import { Table, TableColumn, Select, Option } from "element-ui";
import PPagination from "src/components/UIComponents/Pagination.vue";
// import users from "../Tables/users.js";
import swal from 'sweetalert2'
import { mapGetters } from "vuex";

Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Select);
Vue.use(Option);

export default {
  components: {
    PPagination,
  },
  mounted() {
    this.$store.dispatch("fetchProducts", { per_page: 10, page: 1});
  },
  watch: {
      searchQuery: function (val) {
          this.$store.dispatch("fetchProducts", {
              searchQuery: this.searchQuery,
              per_page: this.pagination.per_page,
              page: this.pagination.current_page
          }); 
      }
  },
  computed: {
    ...mapGetters({
      tableData: "getProducts",
      pagination: "getPagination"
    }),
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      // if (!this.searchQuery) {
      //   if(this.locations)
      //   this.pagination.total = this.locations.length;
      //   else
      //   this.pagination.total = 0
      //   return this.pagedData;
      // }
      // let result = this.locations.filter(row => {
      //   let isIncluded = false;
      //   for (let key of this.propsToSearch) {
      //     let rowValue = row[key].toString();
      //     if (rowValue.includes && rowValue.includes(this.searchQuery)) {
      //       isIncluded = true;
      //     }
      //   }
      //   return isIncluded;
      // });
      // this.pagination.total = result.length;
      // return result.slice(this.from, this.to);
    },
  },
  data() {
    return {
      perPageOptions: ["10", "30", "50", "100", "250", "500"],
      searchQuery: "",
      propsToSearch: ["product_id", "name", "company", "product_type", "quantity", "purchase_price", "sale_price", "supplier_name"],
      tableColumns: [
        {
          prop: "id",
          label: "ID",
          minWidth: 50,
          hidden: true,
          align: 'center'
        },
        {
          prop: "product_id",
          label: "Prod ID",
          minWidth: 150,
          fixed: true,
          sortable: true,
          align: 'center'
        },
        {
          prop: "name",
          label: "Name",
          minWidth: 250,
          sortable: true,
          align: 'left'
        },
        {
          prop: "hsn",
          label: "HSN Code",
          minWidth: 250,
          align: 'center'
        },
        {
          prop: "company",
          label: "Company",
          minWidth: 250,
          align: 'left'
        },
        {
          prop: "product_type.name",
          label: "Type",
          minWidth: 150,
          sortable: true,
          align: 'left'
        },
        {
          prop: "quantity",
          label: "Quantity",
          minWidth: 150,
          sortable: true,
          align: 'center',
          formatter: function(row, column, cellValue, index){
            return `${row.quantity} ${row.product_type.unit}`; 
          }
        },
        {
          prop: "purchase_price",
          label: "P. Price",
          minWidth: 150,
          sortable: true,
          align: 'center'
        },
        {
          prop: "sale_price",
          label: "S. Price",
          minWidth: 150,
          sortable: true,
          align: 'center'
        },
        {
          prop: "supplier_name",
          label: "Supplier",
          minWidth: 250,
          align: 'left'
        },
        {
          prop: "gst_type.name",
          label: "GST",
          minWidth: 200,
          align: 'center'
        }
      ],
      // tableData: this.locations
    };
  },
  methods: {
    changePerPage(){
      this.$store.dispatch("fetchProducts", { per_page : this.pagination.per_page, page : 1, searchQuery : this.searchQuery});
    },
    changePage(){
      this.$store.dispatch("fetchProducts", { per_page : this.pagination.per_page, page : this.pagination.current_page, searchQuery : this.searchQuery});
    },
    handleLike(index, row) {
      // alert(`Your want to like ${row.name}`);
      this.$router.push(`/products/view/${row.id}`)
    },
    handleEdit(index, row) {
      this.$router.push(`/products/edit/${row.id}`)
    },
    handleDelete(index, row) {
      // this.$store.dispatch('deleteProduct', row.id)
      swal({
          title: "Are you sure?",
          text: `You won't be able to revert this!`,
          showCancelButton: true,
          confirmButtonClass: "btn btn-danger btn-fill",
          cancelButtonClass: "btn btn-default btn-fill",
          confirmButtonText: "Delete it!",
          buttonsStyling: false
      })
      .then(result => { this.$store.dispatch("deleteProduct", row.id); })
      .catch(cancel => { console.log(cancel) });
    }
  }
};
</script>

<style lang="scss">
  .el-table .td-actions {
    button.btn {
      margin-right: 5px;
    }
  }


  .el-table, 
  .el-table tr, 
  .el-table thead th {
    background-color: white!important;
  }
</style>
